import { getBodyItemComponent } from 'components/Article/getBodyItemComponent';
import { transformHtmlCardLinksToHashLinks } from 'lib/transformHtmlCardLinksToHashLinks';

/**
 * Generates the markup for a card component based on the provided body items and properties.
 *
 * @param {object} params - The parameters for the function.
 * @param {object[]} params.bodyItems - The array of body items to be rendered.
 * @param {string} params.path - The path for the link if the item is a heading.
 * @param {boolean} params.showHiddenMarkupAndEmbeds - Flag indicating if hidden markup and embeds should be shown.
 * @param {Function} params.setShowHiddenMarkupAndEmbeds - Callback to show hidden markup and embeds.
 * @param {string} params.id - The ID of the card element.
 * @param {string} params.headline - The headline of the card.
 * @returns {React.ReactElement[]} The rendered card markup.
 */
export const cardMarkup = ({
  bodyItems,
  path,
  showHiddenMarkupAndEmbeds,
  setShowHiddenMarkupAndEmbeds,
  id,
  headline,
}) => {
  let showingShowMoreButton = false;

  return bodyItems?.map((bodyItem, index) => {
    const html = bodyItem?.html;
    const isShowMoreButton = bodyItem.type === 'embeddedWidget' && bodyItem.widget?.name === 'CUSTOM_EMBED' && bodyItem.widget?.properties?.embed.type === 'SHOW_MORE_BUTTON';

    if (showingShowMoreButton) {
      return null;
    }

    if (!html) {
      showingShowMoreButton = isShowMoreButton;
      return (isShowMoreButton && showHiddenMarkupAndEmbeds)
        ? null : getBodyItemComponent({
          item: bodyItem,
          i: index,
          path,
          setShowHiddenMarkupAndEmbeds,
          isCard: true,
          parentId: id,
          parentHeadline: headline,
        });
    }

    const transformedBodyItem = {
      ...bodyItem,
      html: transformHtmlCardLinksToHashLinks(bodyItem.html),
    };

    return getBodyItemComponent({
      item: transformedBodyItem,
      i: index,
      path,
      isCard: true,
      parentId: id,
      parentHeadline: headline,
    });
  });
};
