import React from 'react';
import PropTypes from 'prop-types';
import { article as ArticlePropType } from 'lib/CustomPropTypes';
import { Bacon } from 'components/packages/Bacon';
import { formatDataForBacon } from './relatedArticlesHelper';

import styles from './styles.module.scss';


/**
 * Related component to display related articles.
 *
 * @param {object} props - Component properties.
 * @param {string} props.vertical - The vertical of the article.
 * @param {Array} props.relatedArticles - Array of related articles.
 * @param {object} props.article - The main article object.
 * @returns {React.ReactElement} The rendered component.
 */
const Related = ({
  vertical, relatedArticles, article,
}) => (
  <div className={`${styles.container} dn-print related-articles-container`} data-testid="related-articles">
    {relatedArticles && (
      <Bacon
        content={formatDataForBacon(relatedArticles, article)}
        vertical={vertical}
        useAltTitle={vertical === 'today'}
      />
    )}
  </div>
);

Related.propTypes = {
  relatedArticles: PropTypes.arrayOf(ArticlePropType).isRequired,
  article: ArticlePropType.isRequired,
  vertical: PropTypes.string.isRequired,
};


export default Related;
