/* eslint-disable react/jsx-props-no-spreading */

import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { liveBlogCard } from 'lib/CustomPropTypes';

import { VerticalContext, LiveBlogContext } from 'lib/ContextTypes';
import InlineVideo from 'components/InlineVideo';
import SocialShareList from 'components/Social/ShareList';
import { Copy } from 'components/Social/UserActions/Copy';

import { CardByline } from 'components/Card/CardByline';
import { CardHeadline } from 'components/Card/CardHeadline';
import { CardTimestamp } from 'components/Card/CardTimestamp';

import { getSiteConfigs } from 'lib/vertical';
import './styles.themed.scss';
import { cardMarkup } from './CardUtils';

const hiddenStyles = {
  display: 'inline-block',
  position: 'absolute',
  overflow: 'hidden',
  clip: 'rect(0 0 0 0)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
};

/**
   * Card component for displaying various types of content.
   *
   * @param {object} props - The properties object.
   * @param {string} props.cardType - The type of the card (e.g., 'VIDEO').
   * @param {Array} props.authors - The authors of the card content.
   * @param {object} props.content - The content object.
   * @param {Array} [props.content.markupAndEmbeds=[]] - Data from the API, used if `visibleMarkupAndEmbeds` isn't passed in.
   * @param {Array} props.content.visibleMarkupAndEmbeds - The visible markup and embeds.
   * @param {Array} props.content.hiddenMarkupAndEmbeds - The hidden markup and embeds.
   * @param {object} props.date - The date object containing publication date.
   * @param {object} props.headline - The headline object.
   * @param {string} props.headline.primary - The primary headline text.
   * @param {boolean} [props.isHeadlineHidden=false] - Flag to hide the headline.
   * @param {string} props.id - The unique identifier for the card.
   * @param {string} props.path - The path for the card content.
   * @param {object} props.cardData - The video data object.
   * @param {string} props.socialUrl - The URL for social sharing.
   * @param {boolean} props.newActiveItem - Flag to indicate if the item is new and active.
   * @returns {JSX.Element} The rendered Card component.
 */
const Card = ({
  cardType,
  authors,
  content: {
    /**
     * markupAndEmbeds should be data from the API, and should be respected if
     * `visibleMarkupAndEmbeds` isn't passed in
     */
    markupAndEmbeds = [],
    visibleMarkupAndEmbeds,
    hiddenMarkupAndEmbeds,
  },
  date,
  headline: { primary: primaryHed },
  isHeadlineHidden = false,
  id,
  path,
  cardData: videoData,
  socialUrl,
  newActiveItem,
}) => {
  const vertical = useContext(VerticalContext);
  const { isLive } = useContext(LiveBlogContext);
  const [showHiddenMarkupAndEmbeds, setShowHiddenMarkupAndEmbeds] = React.useState(false);
  const overflowContentRef = React.useRef(null);

  React.useEffect(() => {
    if (showHiddenMarkupAndEmbeds && overflowContentRef.current) {
      overflowContentRef.current.focus();
    }
  }, [showHiddenMarkupAndEmbeds]);

  const cardClasses = classNames(
    'live-blog-card',
    'article-body__content',
    'w-100 mh0 mb8 fn',
    { newActiveItem },
  );

  const encodedSocialUrl = encodeURIComponent(socialUrl);

  const icid = 'ed_npd_nn_nn_np_liveblog';
  const dataTrackCardUrlIcid = 'live-blog-card-link';

  const { twitterIntentVia: via } = getSiteConfigs(vertical);


  /**
   * if `visibleMarkupAndEmbeds` was not generated and passed in, but markupAndEmbeds exists, then
   * we should use that array instead, which should be data coming from the API directly
   */
  const cardBodyItems = visibleMarkupAndEmbeds || markupAndEmbeds;

  return (
    <div
      className={cardClasses}
      key={id}
      id={id}
      data-test="card-container"
      data-testid="card-container"
    >
      {date && (
        <CardTimestamp date={date} isLive={isLive} />
      )}
      {!isHeadlineHidden && primaryHed && (
        <CardHeadline headline={primaryHed} />
      )}
      <CardByline authors={authors} />
      <div
        data-icid={dataTrackCardUrlIcid}
        data-testid="card-body-container"
      >
        {cardMarkup({
          bodyItems: cardBodyItems,
          path,
          showHiddenMarkupAndEmbeds,
          setShowHiddenMarkupAndEmbeds,
          id,
          headline: primaryHed,
        })}

        {showHiddenMarkupAndEmbeds
          ? (
            <>
              {/* H3 is because CSS for Article/Cards relies on direct descendant and sibling selectors, adding wrapping markup would break the styles. */}
              <h3 tabIndex="-1" ref={overflowContentRef} style={hiddenStyles}>
                {primaryHed}
                , continued
              </h3>
              {cardMarkup({
                bodyItems: hiddenMarkupAndEmbeds, path, id, headline: primaryHed,
              })}
            </>
          )
          : (
            <div id={`${id}-overflow-content`} className={classNames('hidden-show-more-content', { dn: !hiddenMarkupAndEmbeds?.length })}>
              {cardMarkup({
                bodyItems: hiddenMarkupAndEmbeds, path, id, headline: primaryHed,
              })}
            </div>
          )}

        {cardType === 'VIDEO' && <InlineVideo video={videoData} />}
      </div>
      <div className="share-list__wrapper mt6">
        <SocialShareList
          twitterHref={`https://twitter.com/intent/tweet?text=${encodeURIComponent(primaryHed)}&via=${via}&url=${encodedSocialUrl}&original_referer=${encodedSocialUrl}`}
          facebookHref={`https://www.facebook.com/sharer/sharer.php?u=${encodedSocialUrl}`}
          emailHref={`mailto:?subject=${primaryHed}&body=${encodedSocialUrl}`}
          classes="df justify-start lh-none"
          tracking="mbt_navbar_socialShare"
          primaryHeadline={primaryHed}
        />
        <Copy contentURL={socialUrl} headline={primaryHed} icid={icid} className="share--card" />
      </div>
    </div>
  );
};

Card.propTypes = {
  ...liveBlogCard,
  socialUrl: PropTypes.string.isRequired,
  newActiveItem: PropTypes.bool,
};

Card.defaultProps = {
  newActiveItem: false,
};

export { Card };
