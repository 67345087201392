/**
 * Formats the related articles data for the Bacon component
 * @param {Array} relatedArticles - Related articles data
 * @param {object} article - Article data
 */
export const formatDataForBacon = (relatedArticles, article) => {
  if (!relatedArticles || !article) return null;

  const formattedArticlesCollection = relatedArticles.map(({
    id,
    datePublished,
    unibrow,
    headline,
    url,
    teaseImage,
    alternateTeaseImages,
    ecommerceEnabled,
    cartVisibility,
  }) => ({
    type: 'article',
    id: id || '',
    item: {
      datePublished: datePublished || '',
    },
    computedValues: {
      unibrow: unibrow || {},
      headline: (headline?.primary) || '',
      url: (url?.primary) || '',
      teaseImage: teaseImage || {},
      alternateTeaseImages: alternateTeaseImages || [],
      isShoppable: (ecommerceEnabled && cartVisibility === 'AVAILABLE_PRODUCTION') || false,
    },
  }));


  const data = {
    subType: 'twoOneLargeItems',
    type: 'relatedArticles',
    items: formattedArticlesCollection,
    metadata: {
      title: 'Related',
      showTimestamp: true,
    },
  };

  // If there is a primary taxonomy label, use that as the title
  const metadataSource = article?.taxonomy?.primaryLabel || article.unibrow;

  if (metadataSource) {
    data.metadata = {
      ...data.metadata,
      title: metadataSource.name ? `More ${metadataSource.name}` : 'Related',
      seeAllUrl: metadataSource.url.primary === '/' ? null : metadataSource.url.primary,
    };
  }

  return data;
};
