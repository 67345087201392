/* eslint-disable  react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash.get';
import { Trans } from 'react-i18next';

import { article as ArticlePropType } from 'lib/CustomPropTypes';
import { listenForHFSBump, disconnectHFSBump } from 'lib/listenForHFSBump';
import BTE from 'lib/BTE';
import { verticalSlugMap } from 'lib/vertical';
import { getDataActivityMapID } from 'lib/articleUtils';
import {
  ARTICLE_TAXONOMY_SECTION_NUMBERING,
  MYNEWS_ENABLED,
  ARTICLE_INLINE_NEWSLETTER,
  USER_ACTIONS_BELOW_ARTICLE,
  UNIVERSAL_CHECKOUT_WELLS_FARGO_BANNER,
  SHOW_USER_ACTIONS,
} from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { extractTermPaths } from 'lib/taxonomy';
import {
  ARTICLE,
  AUTHENTICATED,
  UNAUTHENTICATED,
  INITIALIZED,
  UNINITIALIZED,
  UNKNOWN,
} from 'lib/myNewsConstants';
import {
  isBlogArticle,
  isShellArticle,
  shouldShowAd,
} from 'lib/article';
import { withAccountWorkflows } from 'lib/Hooks/useAccountWorkflows';
import { logError } from 'lib/datadog';
import { withGateAccess } from 'lib/Hooks/withGateAccess';
import { withStore, useMyNewsStore } from 'store';
import { VIEW } from 'lib/view';


import Ad from 'components/Ad';
import { BackToTopButton } from 'components/BackToTopButton';
import ContentTimestamp from 'components/ContentTimestamp';
import { LiveBlogPosts } from 'components/LiveBlogPosts';
import { NewsletterSignupInline } from 'components/NewsletterSignup/Inline';
import { RecirculationBacon } from 'components/RecirculationBacon';
import { SocialShareMenu } from 'components/SocialShareMenu';
import MostPopularStoryList from 'components/MostPopularStoryList';
import { Gate } from 'components/Gate';
import { GATE_TITLES } from 'components/Gate/GateTextConstants';
import { LinkIfHref } from 'components/Link/LinkIfHref';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { WellsFargoBanner } from 'components/WellsFargoBanner';
import { BookmarkButton } from 'components/BookmarkButton';
import Related from 'components/Article/Related';
import { FeaturedRecipes } from 'components/Article/FeaturedRecipes';
import { SkipToTableOfContentsOverlay } from 'components/Article/SkipToTableOfContentsOverlay';
import { ExpandedBylineContributors } from '../ExpandedBylineContributors';
import { ArticleInlineByline } from '../ArticleInlineByline';
import { ArticleFoot } from '../ArticleFoot';
import { getNormalizedArticleBody } from '../articleUtils';
import { getSections } from '../getSections';
import { BodyRightRail } from './BodyRightRail';
import { BodyTaboola } from './BodyTaboola';
import {
  listenToScroll,
  monitorArticleView,
} from './bodyUtils';
import { BodyBottomRecommended } from './BodyBottomRecommended';


import './styles.themed.scss';

const block = 'article-body';

const {
  news: NEWS,
  today: TODAY,
} = verticalSlugMap;


/**
 * Maps state to props for the ArticleBody component.
 * @param {object} state - The state from the Redux store.
 * @param {object} state.article - The article state.
 * @param {object} state.myNews - The myNews state.
 * @returns {object} The mapped props.
 */
const mapStateToProps = ({ article, myNews }) => ({
  contentId: get(article, 'content[0].id', ''),
  myNews,
});

class ArticleBody extends React.Component {
  ads = [];

  HFSObserver = null;

  static propTypes = {
    article: ArticlePropType.isRequired,
    contentId: PropTypes.string.isRequired,
    getRightRailAdConfig: PropTypes.func,
    isChromeless: PropTypes.bool,
    view: PropTypes.string,
    isShoppable: PropTypes.bool,
    path: PropTypes.string.isRequired,
    shouldRenderRightRailTaboola: PropTypes.func,
    shouldRenderTaboolaFeed: PropTypes.bool,
    taboolaRecoReelEnabled: PropTypes.bool,
    showCreatedDate: PropTypes.bool,
    taxonomy: PropTypes.shape({
      primarySection: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    vertical: PropTypes.string.isRequired,
    className: PropTypes.string,
    disableGrid: PropTypes.bool,
    showUserActions: PropTypes.bool,
    showBylineTimestamp: PropTypes.bool,
    showInlineByline: PropTypes.bool,
    backToTopBtnsAlignDesktop: PropTypes.oneOf(['top', 'bottom']),
    backToTopBtnsAlignMobile: PropTypes.oneOf(['top', 'bottom']),
    isLiveBlog: PropTypes.bool,
    gateAccess: PropTypes.bool.isRequired,
    mostPopularStoryListItems: PropTypes.array,
    relatedArticles: PropTypes.array,
    saveHistory: PropTypes.func.isRequired,
    savedContentId: PropTypes.string,
    mparticleId: PropTypes.string,
    authenticationState: PropTypes.oneOf([
      AUTHENTICATED,
      UNAUTHENTICATED,
      INITIALIZED,
      UNINITIALIZED,
      UNKNOWN,
    ]),
    bylineTimestampDatePublished: PropTypes.string,
    tableOfContentsEnabled: PropTypes.bool,
  };

  static contextTypes = {
    isLiveBlog: PropTypes.bool,
    isShowBlog: PropTypes.bool,
  };

  static childContextTypes = {
    canonicalUrl: PropTypes.string,
  };

  static defaultProps = {
    authenticationState: UNKNOWN,
    isChromeless: false,
    view: null,
    shouldRenderRightRailTaboola: null,
    shouldRenderTaboolaFeed: true,
    getRightRailAdConfig: null,
    taboolaRecoReelEnabled: false,
    taxonomy: undefined,
    showCreatedDate: true,
    className: null,
    disableGrid: false,
    savedContentId: null,
    mparticleId: null,
    showUserActions: MYNEWS_ENABLED.default,
    showBylineTimestamp: true,
    showInlineByline: true,
    backToTopBtnsAlignDesktop: 'top',
    backToTopBtnsAlignMobile: 'top',
    isLiveBlog: false,
    mostPopularStoryListItems: [],
    relatedArticles: [],
    isShoppable: false,
    bylineTimestampDatePublished: undefined,
    tableOfContentsEnabled: false,
  };

  /**
   * Creates an instance of ArticleBody.
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.state = {
      boxflexRendered: false,
      isScrolledPastFirstWindow: false,
      isScrollingUp: false,
      isInView: false,
      shouldTrackHistory: true,
    };
  }

  /**
   * Gets the child context for the component.
   * @returns {object} The child context.
   */
  getChildContext() {
    const { article } = this.props;
    return {
      canonicalUrl: article?.url?.primary,
    };
  }

  /**
   * Lifecycle method that is called after the component is mounted.
   */
  componentDidMount() {
    window.addEventListener(
      'scroll',
      () => { listenToScroll(this.state, this.updateScrolled); },
    );

    const { article } = this.props;

    BTE.on('articleInView', monitorArticleView(article, this.updateIsInView));

    if (this.ads) {
      this.HFSObserver = listenForHFSBump(this, 'isScrollingUp');
    }
  }

  /**
   * Lifecycle method that is called after the component did update.
   */
  componentDidUpdate() {
    /**
     * Update the user's history when whe are authenticated.
     */
    const {
      authenticationState,
      saveHistory,
      article: { id: contentId },
      savedContentId,
      mparticleId,
    } = this.props;

    if (authenticationState === AUTHENTICATED
      && savedContentId !== contentId
      && mparticleId) {
      saveHistory({ contentId, contentType: ARTICLE });
    }
  }

  /**
   * Lifecycle method that is called before the component is unmounted and destroyed.
   */
  componentWillUnmount() {
    window.removeEventListener(
      'scroll', () => { listenToScroll(this.state, this.updateScrolled); },
    );

    const { article } = this.props;
    BTE.remove('articleInView', monitorArticleView(article, this.updateIsInView));

    if (this.ads) {
      disconnectHFSBump(this.HFSObserver);
    }
  }

  /**
   * Updates the state to indicate whether the user has scrolled past the first window.
   * @param {boolean} isScrolledPastFirstWindow - Indicates if the user has scrolled past the first window.
   */
  updateScrolled = (isScrolledPastFirstWindow) => {
    this.setState({ isScrolledPastFirstWindow });
  }

  /**
   * Updates the state to indicate whether the article is in view.
   * @param {boolean} isInView - Indicates if the article is in view.
   */
  updateIsInView = (isInView) => {
    this.setState({ isInView });
  }

  /**
   * Gets the standard byline for the article.
   * @returns {React.ReactNode} The standard byline.
   */
  getStandardByline = () => {
    const { article } = this.props;
    if (isShellArticle(article)) {
      return null;
    }
    const {
      article: {
        breakingNews,
        ecommerceEnabled,
        headline,
        source,
        taxonomy,
        url,
        date: { createdAt: dateCreated, publishedAt: articleDatePublished },
      },
      contentId,
      showCreatedDate,
      vertical,
      showUserActions,
      showBylineTimestamp,
      showInlineByline,
      isLiveBlog,
      bylineTimestampDatePublished,
      tableOfContentsEnabled,
    } = this.props;

    const articleAuthors = article.authors || [];
    const authors = articleAuthors.filter(({ authorType }) => authorType === 'author');

    const { organization: { name: orgName, externalUrl: orgUrl } = {} } = source || {};

    const isLiveBreakingNews = breakingNews || isLiveBlog;
    const showSubline = showUserActions || showBylineTimestamp || showInlineByline;
    const userActions = getFeatureConfigForBrand(SHOW_USER_ACTIONS, vertical);
    const { showPinterest = false, showBookmark = false } = userActions || {};
    const datePublished = bylineTimestampDatePublished || articleDatePublished;

    return (
      <>
        {showSubline && (
          <section className={classNames({
            mb7: !ecommerceEnabled && !isLiveBreakingNews,
            mb6: !ecommerceEnabled && isLiveBreakingNews,
          })}
          >
            {showUserActions && !showPinterest ? (
              <div className="article-social-share-top" data-testid="article-body-social-share-menu">
                <SocialShareMenu
                  url={url.primary}
                  headline={headline.tease}
                  contentId={contentId}
                  pageRegion="article-top"
                  trackingEventName="article_social_share"
                />
              </div>
            ) : null}

            {showUserActions && showBookmark ? (
              <div className="article-bookmark-menu" data-testid="article-body-bookmark-menu">
                <BookmarkButton contentId={contentId} contentType={ARTICLE} pageRegion="article-top" />
              </div>
            ) : null}

            {showBylineTimestamp && (
              <div className={`${block}__date-source`} data-testid="article-body-timestamp">
                <ContentTimestamp
                  dateCreated={dateCreated}
                  datePublished={datePublished}
                  showCreatedDate={showCreatedDate}
                  delimiter=", "
                  meta
                  hideMicroData
                />
                {source && orgName && (
                  <span>
                    {(dateCreated || datePublished) ? ' / ' : ''}
                    <Trans>
                      Source
                    </Trans>
                    {': '}
                    <LinkIfHref className="article-source__link" href={orgUrl}>
                      {orgName}
                    </LinkIfHref>
                  </span>
                )}
              </div>
            )}
            {showInlineByline && (
              <div className="article-body__byline">
                <ArticleInlineByline
                  authors={authors}
                  vertical={vertical}
                  taxonomy={taxonomy}
                />
                {tableOfContentsEnabled && <SkipToTableOfContentsOverlay />}
              </div>
            )}
          </section>
        )}
      </>
    );
  }

  /**
   * Gets the developing story tag for the article.
   * @returns {React.ReactNode} The developing story tag.
   */
  getDevelopingStoryTag = () => {
    const { isLiveBlog } = this.props;

    const {
      article: {
        breakingNews,
        breakingTagDisabled,
      },
    } = this.props;

    if (!breakingNews || isLiveBlog) {
      return null;
    }
    if (breakingTagDisabled) {
      return null;
    }

    return (
      <div className="articleDevelopingTag">
        <div className="articleDevelopingTag__stripe-border">
          <span className="articleDevelopingTag__stripe-border--solid" />
        </div>
        <div className="articleDevelopingTag__content">
          <Trans i18nKey="developingStory">
            This is a
            <span className="articleDevelopingTag__content--red"> developing </span>
            story. Please check back for updates.
          </Trans>
        </div>
      </div>
    );
  }

  /**
   * Gets the expanded byline for the article.
   * @returns {React.ReactNode} The expanded byline.
   */
  getExpandedByline = () => {
    const { article } = this.props;
    if (isShellArticle(article)) {
      return null;
    }

    return (
      <ExpandedBylineContributors
        article={article}
        additionalClasses="articleBylineContainer"
        bylineAdditionalClasses="article-expanded-byline"
        data-test="article-byline"
      />
    );
  };

  /**
   * Gets the CSS classes for the body container.
   * @returns {object} The CSS classes.
   */
  getBodyContainerClasses = () => {
    const {
      article: {
        ecommerceEnabled,
        primaryImage,
        primaryMedia,
        taxonomy,
      },
      vertical,
      isLiveBlog,
    } = this.props;

    const conditionalClasses = {
      'article-body__grid--container-no-main-image': !primaryImage && !primaryMedia,
      'article-body__ecommerce-enabled': ecommerceEnabled,
      'article-body__grid--container-live-blog': isLiveBlog,
    };

    const hasArticleSectionCounter = getFeatureConfigForBrand(
      ARTICLE_TAXONOMY_SECTION_NUMBERING,
      vertical,
    );

    if (Array.isArray(hasArticleSectionCounter)) {
      const termPaths = extractTermPaths(taxonomy);
      const hasMatchingClass = hasArticleSectionCounter.filter((tax) => termPaths.includes(tax));
      conditionalClasses['article-body__section-counter'] = hasMatchingClass.length > 0;
    }

    return conditionalClasses;
  }

  /**
   * Checks if the Boxflex ad has rendered.
   * @param {object} mpsAd - The MPS ad object.
   */
  boxflexAdRenderCheck = (mpsAd) => {
    mpsAd.onRender((event) => {
      if (!event || event.empty) {
        return;
      }
      this.setState({
        boxflexRendered: true,
      });
    });
  }

  /**
   * Determines if the Today Gift Guide should be rendered.
   * @returns {boolean} True if the Today Gift Guide should be rendered, false otherwise.
   */
  shouldRenderTodayGiftGuide = () => {
    const { article, article: { ecommerceEnabled }, vertical } = this.props;

    const subTypeIsNotLiveBlogOrBlog = !isBlogArticle(article);

    return ecommerceEnabled && vertical === TODAY && subTypeIsNotLiveBlogOrBlog;
  }

  /**
   * Registers an ad element.
   * @param {HTMLElement} el - The element to register.
   */
  registerAd(el) {
    const ad = el && el.querySelector('.article-body__right-rail--ad');
    if (ad) {
      this.ads = [...this.ads, ad];
    }
  }

  /**
   * Renders the interstitial ad if applicable.
   * @returns {React.ReactNode|null} The interstitial ad component or null.
   */
  interstitialAd() {
    const { vertical, article } = this.props;

    if (shouldShowAd(article) && [NEWS, TODAY].includes(vertical)) {
      return (
        <Ad
          slot="interstitial"
          refreshInterval={0}
        />
      );
    }

    return null;
  }

  /**
   * Renders the live blog component if applicable.
   * @returns {React.ReactNode|null} The live blog component or null.
   */
  renderLiveBlog = () => {
    const { isLiveBlog } = this.props;

    if (isLiveBlog) {
      const {
        article: {
          body, url: { canonical },
        },
        backToTopBtnsAlignDesktop,
        backToTopBtnsAlignMobile,
      } = this.props;

      // Get taxonomy from body
      const taxonomy = body.filter((item) => item.type === 'embeddedTaxonomy')[0] || null;
      if (taxonomy) {
        return (
          <ErrorBoundary errorLogger={logError}>
            <LiveBlogPosts
              articleCanonicalUrl={canonical}
              backToTopBtnsAlignDesktop={backToTopBtnsAlignDesktop}
              backToTopBtnsAlignMobile={backToTopBtnsAlignMobile}
            />
          </ErrorBoundary>
        );
      }
    }
    return null;
  }

  /**
   * Renders the social and sponsored content.
   * @param {object} params - The parameters.
   * @param {string} params.bodyClasses - The CSS classes for the body.
   * @param {object} params.nativeAd - The native ad object.
   * @param {object} params.source - The source object.
   * @returns {React.ReactNode|null} The social and sponsored content or null.
   */
  renderSocialAndSponsored = ({
    bodyClasses, nativeAd, source,
  }) => {
    const { article } = this.props;
    if (isShellArticle(article)) {
      return null;
    }

    return (
      <div className="article-body__section">
        <div className={bodyClasses}>
          {nativeAd && source && source.copyright && (
            <p className="article-body__sponsored-by-text">{source?.copyright}</p>
          )}
        </div>
      </div>
    );
  }

  /**
   * Renders the right rail section of the article.
   * @param {object} params - The parameters.
   * @param {object} params.section - The section object.
   * @param {number} params.sectionIndex - The index of the section.
   * @param {boolean} params.articleContainsLargeProduct - Indicates if the article contains a large product.
   * @returns {React.ReactNode} The right rail section.
   */
  renderRightRail = ({
    section, sectionIndex, articleContainsLargeProduct,
  }) => {
    const {
      shouldRenderRightRailTaboola,
      getRightRailAdConfig,
      path: currentPath,
    } = this.props;

    const { boxflexRendered, isScrollingUp, isInView } = this.state;
    return (
      <div
        className={`${block}--right-rail-container`}
        ref={(el) => this.registerAd(el)}
      >
        <BodyRightRail
          block={block}
          section={section}
          sectionIndex={sectionIndex}
          articleContainsLargeProduct={articleContainsLargeProduct}
          shouldRenderRightRailTaboola={shouldRenderRightRailTaboola}
          getRightRailAdConfig={getRightRailAdConfig}
          currentPath={currentPath}
          boxflexRendered={boxflexRendered}
          isScrollingUp={isScrollingUp}
          showTaboola={isInView}
          boxflexAdRenderCheck={this.boxflexAdRenderCheck}
        />
      </div>
    );
  }

  /**
   * Renders the ArticleBody component.
   * @returns {React.ReactNode} The rendered component.
   */
  render() {
    const {
      article,
      isChromeless,
      view,
      isShoppable,
      path,
      taboolaRecoReelEnabled,
      vertical,
      className,
      disableGrid,
      shouldRenderTaboolaFeed,
      contentId,
      showUserActions,
      isLiveBlog,
      gateAccess,
      mostPopularStoryListItems,
      relatedArticles,
    } = this.props;
    // split up for sonarcube smell test on readability
    const isNotShellArticle = !isShellArticle(article);
    const hasSocialsBelowArticle = showUserActions
      && getFeatureConfigForBrand(USER_ACTIONS_BELOW_ARTICLE, vertical);
    const hasSocialMenuBelowArticle = isNotShellArticle
      && hasSocialsBelowArticle;

    if (!article) {
      return null;
    }

    const {
      isScrolledPastFirstWindow, isInView,
    } = this.state;

    const {
      adsEnabled,
      breakingNews,
      ecommerceEnabled,
      nativeAd,
      source,
      headline,
      url,
    } = article;

    const articleBody = getNormalizedArticleBody(article);
    const articleContainsLargeProduct = isShoppable || !!articleBody.find(
      ({ type, presentation }) => (type === 'embeddedProduct' && presentation.size === 'large'),
    );
    const articleContainsUniCheckout = !!articleBody.find(
      ({ type }) => (type === 'embeddedProduct'),
    );
    const isPrimarySectionFood = (article?.taxonomy?.primarySection?.name ?? '').toLowerCase() === 'food';

    const embeddedRecipes = articleBody.filter((item) => item.type === 'embeddedRecipe');
    const shouldShowFeaturedRecipes = embeddedRecipes && embeddedRecipes.length > 1
      && isPrimarySectionFood;

    const sections = getSections({
      adsEnabled,
      article,
      articleBody,
      breakingNews,
      ecommerceEnabled,
      isShoppable,
      isLiveBlog,
      nativeAd,
      path,
      taboolaRecoReelEnabled,
      vertical,
      gateAccess,
    });

    const sectionsIndex = sections.map((section, index) => ({ index, ...section }));
    const sectionLast = sections.length - 1;

    const gridContainerClasses = classNames(
      'article-body__grid--container',
      this.getBodyContainerClasses(),
      {
        'article_body__grid--shell-container': isShellArticle(article),
        // class is targeted in shell article pages
        'article-body__gridContainer': isShellArticle(article),
      },
    );

    const gridBodyClasses = classNames(
      { 'layout-grid-item layout-grid-item--with-gutter-s-only grid-col-10-m grid-col-push-1-m grid-col-6-xl grid-col-push-2-xl': !disableGrid },
      { 'article-body--custom-column': !isShellArticle(article) && !disableGrid },
    );

    const bodyGridClasses = classNames(
      'article-body',
      gridBodyClasses,
    );
    const bodyFullWidthClasses = 'article-body layout-grid-item';

    const dataTaboolaTarget = !isChromeless && !isLiveBlog && gateAccess ? 'read-more' : '';

    const shouldRenderBackToTopButton = isScrolledPastFirstWindow
      && !isLiveBlog
      && ecommerceEnabled;

    const dataActivityMapID = getDataActivityMapID({
      pageRegion: 'article-body',
      componentName: 'article',
    });

    const isNews = vertical === NEWS;
    const userActions = getFeatureConfigForBrand(SHOW_USER_ACTIONS, vertical);
    const { showPinterest } = userActions || {};

    const isShell = article.presentation.style === 'shell';
    const isStartToday = view === VIEW.START_TODAY_APP;

    const showMostPopular = isNews
      && !breakingNews
      && !isChromeless
      && !nativeAd
      && !isShell
      && !ecommerceEnabled;

    const shouldRenderRelated = isNotShellArticle
      && relatedArticles?.length >= 3
      && !isStartToday
      && !isChromeless;

    /**
     * Renders the article body sections.
     * @returns {React.ReactNode} The rendered article body sections.
     */
    const renderArticleBody = () => (
      sectionsIndex.map((section, i) => (
        <div
          key={section.index}
          className={classNames(
            { 'article-body__section layout-grid-container': (!disableGrid && !section.isFullWidth) },
            {
              'article-body__last-section': i === sections.length - 1,
              'article-body__first-section': i === 0,
            },
          )}
        >
          {articleContainsUniCheckout
            && getFeatureConfigForBrand(UNIVERSAL_CHECKOUT_WELLS_FARGO_BANNER, vertical)
            && <WellsFargoBanner bodyClasses={bodyGridClasses} />}
          <div className={section.isFullWidth ? bodyFullWidthClasses : bodyGridClasses} data-test="articleBody">
            {i === 0 && (
              <>
                {this.getStandardByline()}
              </>
            )}
            {shouldShowFeaturedRecipes
              ? <FeaturedRecipes embeddedRecipes={embeddedRecipes} /> : null}

            <div className="article-body__content">
              {/* Render section components */}
              {section.items}
            </div>
            {i === sectionLast
              && isLiveBlog
              && hasSocialMenuBelowArticle && (
            // Render below article social icons for blogs
              <SocialShareMenu
                url={url.primary}
                headline={headline.tease}
                contentId={contentId}
                trackingEventName="article_social_share_bottom"
                actions={{ pinterest: showPinterest }}
              />
            )}
            <div className="article-body__content">
              {/* Render live blog in last article section */}
              {i === sectionLast
                && (breakingNews || isLiveBlog)
                && (
                  <>
                    {this.renderLiveBlog()}
                    {this.getDevelopingStoryTag()}
                    {getFeatureConfigForBrand(ARTICLE_INLINE_NEWSLETTER, vertical)
                      && vertical !== 'today'
                      && <NewsletterSignupInline article={article} vertical={vertical} />}
                  </>
                )}
              {i === sectionLast && (
                <>
                  {this.getExpandedByline()}
                  {hasSocialMenuBelowArticle && !isLiveBlog && !isStartToday ? (
                    <div className="article-social-share-bottom">
                      <SocialShareMenu
                        url={url.primary}
                        headline={headline.tease}
                        contentId={contentId}
                        pageRegion="article-bottom"
                        trackingEventName="article_social_share_bottom"
                        actions={{ pinterest: showPinterest }}
                      />
                    </div>
                  ) : null}
                </>
              )}
            </div>

          </div>
          {this.renderRightRail({
            section, sectionIndex: i, articleContainsLargeProduct,
          })}
        </div>
      ))
    );

    /**
     * Renders the gated article body.
     * @returns {React.ReactElement} The gated article body.
     */
    const renderGatedArticleBody = () => {
      const section = sectionsIndex[0];
      return (
        <div className="article-body__gate article-body__first-section article-body__section layout-grid-container">
          <div
            data-test="articleBody"
            className={bodyGridClasses}
          >
            {this.getStandardByline()}
            <div
              className={
                classNames(
                  'article-body__content',
                  'article-body__content--gated',
                )
              }
            >
              {/* Render section components */}
              {section.items}
            </div>
            <div className="gated-content--wrapper">
              <Gate title={GATE_TITLES.article} />
            </div>
          </div>
          {this.renderRightRail({
            section: sections[0], sectionIndex: 0, articleContainsLargeProduct,
          })}
        </div>
      );
    };

    return (
      <>
        <div
          className={classNames(
            block,
            className,
            {
              breaking: breakingNews,
              'is-live-blog': isLiveBlog,
            },
          )}
          data-activity-map={dataActivityMapID}
          data-taboola-target={dataTaboolaTarget}
          data-testid="article-body"
        >
          {shouldRenderBackToTopButton && <BackToTopButton />}
          <div className={gridContainerClasses}>
            {this.interstitialAd()}
            {this.renderSocialAndSponsored({ bodyClasses: bodyGridClasses, nativeAd, source })}

            {gateAccess ? renderArticleBody() : renderGatedArticleBody()}

            {/* TODO: move to ArticleFoot? */}
            {!isShoppable && (
              <RecirculationBacon pageRegion="article-bottom" />
            )}

            {/* TODO: move to ArticleFoot? */}
            <BodyBottomRecommended
              block={block}
              currentPath={path}
              gridBodyClasses={gridBodyClasses}
              shouldRenderTodayGiftGuide={this.shouldRenderTodayGiftGuide()}
              showTaboola={isInView}
            />
          </div>
          {showMostPopular && (
            <MostPopularStoryList
              mostPopularStoryListItems={mostPopularStoryListItems}
              vertical={vertical}
            />
          )}
        </div>

        <ArticleFoot vertical={vertical} currentPath={path} article={article} />

        {/* TODO: move to ArticleFoot? */}
        {shouldRenderTaboolaFeed && (
          <BodyTaboola
            article={article}
            showTaboola={isInView}
          />
        )}
        {shouldRenderRelated ? (
          <Related
            article={article}
            relatedArticles={relatedArticles}
            vertical={vertical}
            view
          />
        ) : null}
      </>
    );
  }
}

/**
 * Selector for the zustand store.
 * @param {object} state - The zustand state.
 * @returns {object} - The props for the component.
 */
const selector = (state) => ({
  saveHistory: state.saveHistory,
  mparticleId: state.customer.mparticleId,
  savedContentId: state.savedContentId,
  authenticationState: state.authenticationState,
});

export default connect(mapStateToProps)(
  withStore(
    withAccountWorkflows(withGateAccess(ArticleBody)),
    useMyNewsStore,
    selector,
  ),
);

