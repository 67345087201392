import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { stub as $t } from '@nbcnews/analytics-framework';
import VideoEmbedIcon from './VideoEmbedIcon';

import './styles.themed.scss';

$t('register', 'mbt_navbar_socialShare');

let inputIdCounter = 0;


/**
 *
 * @param {string} trackingID
 * @param {string} platform
 */
const track = (trackingID, platform) => {
  $t('track', trackingID, { platform });
};

/**
 * SocialShareList component to display a list of social share icons.
 *
 * @param {object} props - The properties object.
 * @param {boolean} props.enableMenu - Indicates if the menu is enabled.
 * @param {string} props.twitterHref - The URL for sharing on Twitter.
 * @param {string} props.facebookHref - The URL for sharing on Facebook.
 * @param {string} [props.emailHref] - The URL for sharing via email.
 * @param {string} [props.embedHref] - The URL for embedding.
 * @param {string} [props.smsHref] - The URL for sharing via SMS.
 * @param {object} props.displayOptions - Display options for the share list.
 * @param {boolean} props.showShareThis - Indicates if the "Share this" text should be displayed.
 * @param {string} props.classes - Additional classes for styling.
 * @param {string} [props.tracking=mbt_navbar_socialShare] - The tracking ID.
 * @param {string} props.primaryHeadline - The primary headline to be shared.
 */
const SocialShareList = (
  {
    enableMenu,
    twitterHref,
    facebookHref,
    emailHref,
    embedHref,
    smsHref,
    displayOptions,
    showShareThis,
    classes,
    tracking: trackingID = 'mbt_navbar_socialShare',
    primaryHeadline,
  },
) => {
  const { t } = useTranslation();

  // Increment the counter to avoid input conflicts
  inputIdCounter += 1;

  const className = classNames(
    classes,
    'share-list',
    {
      'share-list__is-knockout': displayOptions.isKnockout,
      'share-list__use-colored-icons': displayOptions.useColoredIcons,
      'share-list__is-full-width-mobile': displayOptions.isFullWidthMobile,
    },
    'dn-print',
  );

  return (
    <div className={className} data-testid="social-share-list">
      <input
        className={classNames('dn', {
          'share-list__social-menu-checkbox': enableMenu,
        })}
        data-testid="social-menu__checkbox"
        defaultChecked={enableMenu === false}
        id={`social-menu-toggle--${inputIdCounter}`}
        type="checkbox"
        tabIndex="-1"
      />

      <ul
        className={classNames('share-list__social-list', 'js-social-share', {
          'justify-between w-100 w-auto-m': displayOptions.isFullWidthMobile,
        })}
      >
        {
          showShareThis && (
            <li
              className={classNames(
                'share-list__social-list-text',
                'founders-mono',
              )}
            >
              {`${t('Share this')} -`}
            </li>
          )
        }

        <li className="share-list__social-list-icon share-list__social-list-icon--first">
          <a
            href={facebookHref}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              track(trackingID, 'facebook');
            }}
            aria-label={`Share to Facebook, ${primaryHeadline}`}
          >
            <span
              className={classNames(
                'icon icon-facebook',
                'share-list__social-icon',
              )}
            />
          </a>
        </li>

        <li
          className="share-list__social-list-icon social-share-list__icon--twitter"
          data-testid="social-share-list__icon--twitter"
        >
          <a
            href={twitterHref}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              track(trackingID, 'twitter');
            }}
            aria-label={`Share to X, ${primaryHeadline}`}
          >
            <span
              className={classNames(
                'icon icon-twitter',
                'share-list__social-icon',
              )}
            />
          </a>
        </li>

        {emailHref && (
          <li
            className="share-list__social-list-icon"
            data-testid="social-share-list__icon--email"
          >
            <a
              href={emailHref}
              onClick={() => {
                track(trackingID, 'email');
              }}
              aria-label={`Email link, ${primaryHeadline}`}
            >
              <span
                className={classNames(
                  'icon icon-email',
                  'share-list__social-icon',
                )}
              />
            </a>
          </li>
        )}

        {smsHref && (
          <li
            className={classNames('share-list__social-list-icon', 'db dn-m')}
            data-testid="social-share-list__icon--sms"
          >
            <a
              className="js-sms-link"
              href={smsHref}
              onClick={() => {
                track(trackingID, 'sms');
              }}
            >
              <span
                className={classNames(
                  'icon icon-sms',
                  'share-list__social-icon',
                )}
              />
            </a>
          </li>
        )}

        {embedHref && (
          <li
            className={classNames('share-list__social-list-icon', 'dn db-m')}
            data-testid="social-share-list__icon--embed"
          >
            <VideoEmbedIcon
              href={embedHref}
              tracking={trackingID}
              classes={classNames('icon icon-embed', 'share-list__social-icon')}
            />
          </li>
        )}
      </ul>

      {/* eslint-disable jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */}
      {enableMenu && (
        <label
          className="share-list__social-menu-expand"
          data-testid="social-share-list__icon--menu"
          htmlFor={`social-menu-toggle--${inputIdCounter}`}
        >
          <span className="share-list__social-menu-dot" />
          <svg viewBox="0 0 20 20" className="share-list__social-menu-close">
            <line
              x1="0"
              x2="20"
              y1="0"
              y2="20"
              className="share-list__social-menu-close-line"
            />
            <line
              x1="0"
              x2="20"
              y1="20"
              y2="0"
              className="share-list__social-menu-close-line"
            />
          </svg>
        </label>
      )}
      {/* eslint-enable jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */}
    </div>
  );
};

SocialShareList.propTypes = {
  enableMenu: PropTypes.bool,
  twitterHref: PropTypes.string.isRequired,
  facebookHref: PropTypes.string.isRequired,
  emailHref: PropTypes.string,
  embedHref: PropTypes.string,
  smsHref: PropTypes.string,
  classes: PropTypes.string,
  displayOptions: PropTypes.shape({
    useColoredIcons: PropTypes.bool,
    isKnockout: PropTypes.bool,
    isFullWidthMobile: PropTypes.bool,
  }),
  tracking: PropTypes.string,
  showShareThis: PropTypes.string,
  primaryHeadline: PropTypes.string,
};

SocialShareList.defaultProps = {
  enableMenu: false,
  emailHref: null,
  embedHref: null,
  smsHref: null,
  classes: null,
  displayOptions: {},
  tracking: undefined,
  showShareThis: true,
  primaryHeadline: '',
};

export default SocialShareList;
